<template>
<div class="add_teacher">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>锁客设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="addFromRef" :model="addFrom" :rules="addFromRules" label-width="110px">
  
                 <el-form-item label="万小店锁店时间:" prop="shopTime">
                 <el-input-number :min="-1" step-strictly :step="1" v-model.number="addFrom.shopTime" style="margin-right:15px;"></el-input-number>
                <span>小时</span>
                 <span style="margin-left:15px;color:red">(-1 按之前 0 永久锁客 1 一个小时 2 个小时 以此类推…)</span>
                </el-form-item>
                
                <el-form-item label="直客锁客时间:" prop="userTime">
                    <el-input-number step-strictly :min="-1" :step="1" v-model.number="addFrom.userTime" style="margin-right:15px"></el-input-number>
                     <span>小时</span>
                     <span style="margin-left:15px;color:red">(-1 按之前 0 永久锁客 1 一个小时 2 个小时 以此类推…)</span>
                </el-form-item>
                 
      
            <el-form-item >
                <el-button  type="success" @click="submitForm">保存</el-button>
                <!-- <el-button  type="primary" @click="goBack">返回</el-button> -->
            </el-form-item >
        </el-form>
   
</div>
</template>
<script>
import {
 getSetting,
 subSetting
} from "../../api/lockSetting";
export default {
    
    data(){
        
        return{
           isSubmit:false,
            addFrom: {
                userTime:'',
                shopTime: '',
               
            },
            addFromRules: {
                 shopTime: [ { required: true, message: '请输入万小店锁店时间',trigger: ['blur','change'] }  ],
              userTime: [ { required: true ,message: '请输入直客锁客时间',trigger: ['blur','change'] }],
                  
            }
        }
    },
    created(){
        this.getDetails()
    },
    methods: {
       async getDetails(){
        const {data} = await getSetting()
        if(data.code == 0){
            this.addFrom.shopTime = data.data.shopTime
            this.addFrom.userTime = data.data.userTime
        }
       },
        submitForm(){
          
            this.$refs.addFromRef.validate(async validate=>{
                if(!validate)return
                if(this.isSubmit)return
                this.isSubmit  = true
                const {data} = await subSetting(this.addFrom)
                if(data.code == 0){
                   
                    this.$message.success(data.msg)
                    this.getDetails()
                }else{
                    this.$message.warning(data.msg)
                    
                   
                }
                setTimeout(() => {
                    this.isSubmit = false
                }, 1500);
            })
        },
        goBack(){
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>
 .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
    margin-bottom: 30px;
  }
.item{
    display: flex;
}
.avatar-uploader-icon {
    border: 1px dashed  #eee;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
.avatar{
    width: 150px;
    height: 150px;
   
}
.box-card{
   padding-bottom: 50px;
}
</style>