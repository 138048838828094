import request from '../utils/request'
import baseUrl from './baseUrl'

export const getSetting = (data) => request({
  url: baseUrl + '/lockSet/selectForBack',
  method: 'POST',
  data
})
export const subSetting = (data) => request({
  url: baseUrl + '/lockSet/modify',
  method: 'POST',
  data
})